import * as React from 'react';
import { PageProps } from 'gatsby';
import Seo from '../components/seo';
import GlobalFonts from '../fonts/fonts';
import Navbar from '../components/Navbar';
import FuelPaymentSection from '../sections/FuelPaymentSection';
import CompareSection from '../sections/CompareSection';
import NextDaySection from '../sections/NextDaySection';
import PlatformSection from '../sections/PlatformSection';
import EverythingOnlineSection from '../sections/EverythingOnlineSection';
import Footer from '../components/Footer';
import PartnersSection from '../components/PartnersSection';

const GasStationsPage:React.FC<PageProps> = ({ location }) => (
  <>
    <Seo />
    <GlobalFonts />
    <Navbar location={location} />
    <FuelPaymentSection />
    <CompareSection />
    <NextDaySection />
    <PlatformSection />
    <EverythingOnlineSection />
    <PartnersSection />
    <Footer />
  </>
);

export default GasStationsPage;
