import React from 'react';
import styled from 'styled-components';
import Section from '../components/Section';
import GasStationDieselBank from '../images/gas-station-dieselbank.png';

import Button from '../components/Button';
import { TypographyLarge } from '../components/Typography';

const Container = styled.div`
  max-width: 720px;
  margin-left: 128px;
  position: absolute;
  bottom: 64px;
  @media(max-width:800px){
    width: calc(100% - 48px);
    position: static;
    max-width: none;
    margin-right: 24px;
    margin-left: 24px;
    text-align: center;
    padding-top: 64px;
    padding-bottom: 64px;
  }
`;

const FuelPaymentSection = () => (
  <Section imgUrl={GasStationDieselBank}>
    <Container>
      <TypographyLarge>
        Faça parte da revolução do pagamento de combustíveis
      </TypographyLarge>
      <Button href="mailto:parceiro@dieselbank.com.br">Seja um posto parceiro</Button>
    </Container>
  </Section>
);

export default FuelPaymentSection;
