import React from 'react';
import styled from 'styled-components';
import Section from '../components/Section';
import { TypographyLarge } from '../components/Typography';
import DieselBankCoins from '../images/dieselbank-coins.png';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin-right: 128px;
  margin-left: 128px;
  @media(max-width: 800px){
    flex-direction: column-reverse;
    text-align: center;
    justify-content: center;
    margin-left: 24px;
    margin-right: 24px;
    padding-top: 64px;
    padding-bottom: 64px;
  }
`;

const StyledTypographyLarge = styled(TypographyLarge)`
  max-width: 600px;
  @media(max-width: 800px){
    font-size: 24px;
  }
`;

const Image = styled.img`
  margin: 0;
  max-width: 400px;
  @media(max-width:800px){
    width: 100%;
    margin-top: 24px;
  }
`;

const CompareSection = () => (
  <Section>
    <Container>
      <StyledTypographyLarge black>
        Compare e descubra que oferecemos as taxas mais baixas do mercado.
      </StyledTypographyLarge>
      <Image src={DieselBankCoins} />
    </Container>
  </Section>
);

export default CompareSection;
